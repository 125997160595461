import barba from '@barba/core';
import gsap from "gsap";

export default class{

	constructor(){

        barba.hooks.once((data) => {
            this.run(document)
        })
        barba.hooks.enter((data) => {
            this.run(data.next.container)
        });
    }
    
    run(container){
        const animation_links = container.querySelectorAll('[data-hover-split-trigger]')
        if(animation_links.length > 0){
            animation_links.forEach(link => {
                const str = link.querySelector('[data-hover-split-target]')
                if(str){
                    if(link.getAttribute('aria-label')==null){
                        link.setAttribute('aria-label',str.textContent)
                    }
                    let html = ""
                    const strArray = str.textContent.split('')
                    strArray.forEach(s => {
                        html = html +'<span data-hover-split-str>' + s + '</span>'
                    })
                    const firstEl = document.createElement('span')
                    firstEl.setAttribute('data-hover-split-first-el',"")
                    firstEl.setAttribute('aria-hidden',"true")
                    firstEl.innerHTML = html
                    str.innerHTML = ""
                    str.appendChild(firstEl);

                    const secondEl = document.createElement('span')
                    secondEl.setAttribute('data-hover-split-second-el',"")
                    secondEl.setAttribute('aria-hidden',"true")
                    secondEl.innerHTML = html
                    str.appendChild(secondEl);

                    link.addEventListener("mouseenter",()=>{
                        this.hovanimation(link)
                    })
                    link.addEventListener("touchstart",()=>{
                        this.hovanimation(link)
                    },{ passive: true });
                }
            })
        }
    }

    hovanimation(link){
        const first = link.querySelectorAll('[data-hover-split-first-el] [data-hover-split-str]')
        gsap.fromTo(first,
            {
                y: "0%",
            },
            {
                y: "-100%",
                ease: "power4.easeOut",
                stagger: { 
                    each:.015,
                }
            }
        );
        const second = link.querySelectorAll('[data-hover-split-second-el] [data-hover-split-str]')
        gsap.fromTo(second,
            {
                y: "0%",
            },
            {
                y: "-100%",
                ease: "power4.easeInOut",
                stagger: { 
                    each:.024,
                }
            }
        );
    }
}