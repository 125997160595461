import barba from '@barba/core';
import { Power2, Power3, Power4, Elastic, gsap } from 'gsap';

export default class {
    constructor() {
        barba.hooks.once((data) => {
            this.initMagneticButtons();
        });
        barba.hooks.after((data) => {
            this.initMagneticButtons();
        });
    }

    moveMagnet(event) {
        var magnetButton = event.currentTarget;
        var bounding = magnetButton.getBoundingClientRect();
        var magnetsStrength = magnetButton.getAttribute("data-strength");
        var magnetsStrengthText = magnetButton.getAttribute("data-strength-text");

        gsap.to(magnetButton, 1.5, {
            x: (((event.clientX - bounding.left) / magnetButton.offsetWidth) - 0.5) * magnetsStrength,
            y: (((event.clientY - bounding.top) / magnetButton.offsetHeight) - 0.5) * magnetsStrength,
            rotate: "0.001deg",
            ease: Power4.easeOut
        });

        gsap.to(magnetButton.querySelector(".magnet-text"), 1.5, {
            x: (((event.clientX - bounding.left) / magnetButton.offsetWidth) - 0.5) * magnetsStrengthText,
            y: (((event.clientY - bounding.top) / magnetButton.offsetHeight) - 0.5) * magnetsStrengthText,
            rotate: "0.001deg",
            ease: Power4.easeOut
        });
    }

    initMagneticButtons() {
        var magnets = document.querySelectorAll('.magnetic');
        var strength = 100;

        if (window.innerWidth > 540) {
            magnets.forEach((magnet) => {
                magnet.addEventListener('mousemove', this.moveMagnet.bind(this));
                magnet.parentNode.classList.remove('not-active');
                magnet.addEventListener('mouseleave', function (event) {
                    gsap.to(event.currentTarget, 1.5, {
                        x: 0,
                        y: 0,
                        ease: Elastic.easeOut
                    });
                    gsap.to(event.currentTarget.querySelector(".magnet-text"), 1.5, {
                        x: 0,
                        y: 0,
                        ease: Elastic.easeOut
                    });
                });
            });
        }

        var magneticButtons = document.querySelectorAll('.btn__click.magnetic');
        magneticButtons.forEach((magneticButton) => {
            magneticButton.addEventListener('mouseenter', function () {
                if (magneticButton.querySelector(".btn__fill")) {
                    gsap.to(magneticButton.querySelector(".btn__fill"), .6, {
                        startAt: { y: "76%" },
                        y: "0%",
                        ease: Power2.easeInOut
                    });
                }
                if (magneticButton.querySelector(".btn__text-inner.change")) {
                    gsap.to(magneticButton.querySelector(".btn__text-inner.change"), .3, {
                        startAt: { color: "#1C1D20" },
                        color: "#FFFFFF",
                        ease: Power3.easeIn,
                    });
                }
                magneticButton.parentElement.classList.remove('not-active');
            });

            magneticButton.addEventListener('mouseleave', function () {
                if (magneticButton.querySelector(".btn__fill")) {
                    gsap.to(magneticButton.querySelector(".btn__fill"), .6, {
                        y: "-76%",
                        ease: Power2.easeInOut
                    });
                }
                if (magneticButton.querySelector(".btn__text-inner.change")) {
                    gsap.to(magneticButton.querySelector(".btn__text-inner.change"), .3, {
                        color: "#1C1D20",
                        ease: Power3.easeOut,
                        delay: .3
                    });
                }
                magneticButton.parentElement.classList.remove('not-active');
            });
        });
    }
}
