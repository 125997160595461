import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

export default class{
    

	constructor(){
        barba.hooks.once((data) => {
            this.run(document)
            gsap.registerPlugin(ScrollTrigger);
        });
        barba.hooks.before((data) => {
            ScrollTrigger.killAll();
            document.querySelector("#header").classList.remove("header-white");
            document.querySelector("#header").classList.remove("header-white");
        });
        barba.hooks.after((data) => {
            this.run(data.next.container)
            gsap.registerPlugin(ScrollTrigger);
            document.querySelector("#header").classList.remove("header-hide");
        });
    }
    
    run(container){

        this.headChange = document.querySelectorAll('[data-head-change-trigger]');
        this.headHide = document.querySelectorAll('[data-head-hide-trigger]');
        

        if(this.headChange) {
            this.headChange.forEach((el, i) => {
                gsap.to("#header", {
                    scrollTrigger: {
                        trigger: el,
                        start: "top +=80 top",
                        end: "bottom +=80 top",
                        // toggleActions: 'play none play none',
                        onEnter: () => {
                        document.querySelector("#header").classList.add("header-white");
                        },
                        onEnterBack: () => {
                        document.querySelector("#header").classList.add("header-white");
                        },
                        onLeave: () => {
                        document.querySelector("#header").classList.remove("header-white");
                        },
                        onLeaveBack: () => {
                        document.querySelector("#header").classList.remove("header-white");
                        },
                        // markers: true,
                    },
                });
            })
        }
        if(this.headHide) {
            this.headHide.forEach((el, i) => {
                gsap.to("#header", {
                    scrollTrigger: {
                        trigger: el,
                        start: "top +=80 top",
                        end: "bottom +=80 top",
                        // toggleActions: 'play none play none',
                        onEnter: () => {
                        document.querySelector("#header").classList.add("header-hide");
                        },
                        onEnterBack: () => {
                        document.querySelector("#header").classList.add("header-hide");
                        },
                        onLeave: () => {
                        document.querySelector("#header").classList.remove("header-hide");
                        },
                        onLeaveBack: () => {
                        document.querySelector("#header").classList.remove("header-hide");
                        },
                        // markers: true,
                    },
                });
            })
        }
    }
}