import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class {

    constructor() {
        barba.hooks.afterOnce((data) => {
            this.thoughtsSlider(data.next.container);
            this.worksContainer(data.next.container);
        });
        barba.hooks.after((data) => {
            this.thoughtsSlider(data.next.container);
            this.worksContainer(data.next.container);
        });
    }

    thoughtsSlider(container) {

        let direction = 1;

        const roll1 = this.roll(".home-thoughts__slider--inner", { duration: 30 }),
        scroll = ScrollTrigger.create({
            // trigger: document.querySelector('[data-scroll-container]'),
            onUpdate: (self) => {
                if (self.direction !== direction) {
                    direction *= -1;
                    gsap.to(roll1, { timeScale: direction, overwrite: true });
                }
            }
        });
    }

    roll(targets, vars, reverse) {
        vars = vars || {};
        vars.ease || (vars.ease = "none");
        const tl = gsap.timeline({
            repeat: -1,
            onReverseComplete() {
                this.totalTime(this.rawTime() + this.duration() * 10);
            }
        }),
        elements = gsap.utils.toArray(targets),
        clones = elements.map(el => {
            let clone = el.cloneNode(true);
            el.parentNode.appendChild(clone);
            return clone;
        }),
        positionClones = () => elements.forEach((el, i) => gsap.set(clones[i], { position: "absolute", overwrite: false, top: el.offsetTop, left: el.offsetLeft + (reverse ? -el.offsetWidth : el.offsetWidth) }));
        positionClones();
        elements.forEach((el, i) => tl.to([el, clones[i]], { xPercent: reverse ? 100 : -100, ...vars }, 0));
        window.addEventListener("resize", () => {
            let time = tl.totalTime();
            tl.totalTime(0);
            positionClones();
            tl.totalTime(time);
        });
        return tl;
    }

    worksContainer(container) {

        this.works = document.querySelector(".home-works");
        this.worksWrapper = document.querySelector(".home-works__wrapper");
        this.worksContent = document.querySelector(".home-works__content");
        this.worksBack = document.querySelector(".home-works__bg");

        if (this.works) {
            ScrollTrigger.matchMedia({
                "(min-width: 992px)": () => {
                    gsap.to(this.worksBack, {
                        scrollTrigger: {
                            trigger: this.worksWrapper,
                            endTrigger: this.works,
                            start: "top top",
                            end: "bottom top",
                            pin: this.worksBack,
                            // markers: true,
                        },
                    });
                    gsap.to(this.worksContent, {
                        scrollTrigger: {
                            trigger: this.worksWrapper,
                            endTrigger: this.works,
                            start: "top top",
                            end: "bottom top",
                            pin: this.worksContent,
                            // markers: true,
                        },
                    });
                    this.works.addEventListener('mouseleave', () => {
                        gsap.to(worksImages, { opacity: 0, duration: 0.3 });
                        gsap.to(customerImages, { opacity: 0, duration: 0.3 });
                        gsap.to(worksLink, { opacity: 1, duration: 0.3 });
                        gsap.to(customerLink, { opacity: 1, duration: 0.3 });
                    });
                }
            });
        }

        const worksLink = document.querySelector('.works-link');
        const customerLink = document.querySelector('.customer-link');

        const worksImages = document.querySelector('.works-bg');
        const customerImages = document.querySelector('.customer-bg');

        ScrollTrigger.matchMedia({
            "(min-width: 992px)": () => {
                if (worksLink) {
                    worksLink.addEventListener('mouseenter', () => {
                        gsap.to(worksImages, { opacity: 1, duration: 0.3 });
                        gsap.to(customerImages, { opacity: 0, duration: 0.3 });
                        gsap.to(customerLink, { opacity: .4, duration: 0.3 });
                        gsap.to(worksLink, { opacity: 1, duration: 0.3 });
                    });
                }
                if (customerLink) {
                    customerLink.addEventListener('mouseenter', () => {
                        gsap.to(worksImages, { opacity: 0, duration: 0.3 });
                        gsap.to(customerImages, { opacity: 1, duration: 0.3 });
                        gsap.to(worksLink, { opacity: .4, duration: 0.3 });
                        gsap.to(customerLink, { opacity: 1, duration: 0.3 });
                    });
                }
            }
        });
    }
}
