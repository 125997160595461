import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class {

    constructor() {
        barba.hooks.afterOnce((data) => {
            this.scrollTrigger(data.next.container)
        });
        barba.hooks.after((data) => {
            this.scrollTrigger(data.next.container)
        });
    }

    scrollTrigger(container) {

        this.scrollRandom = gsap.utils.toArray(".js-scroll-random");

        if (this.scrollRandom) {
            this.scrollRandom.forEach((el, i) => {
                let pr_str = el.textContent.split("");
                let str = "";
                pr_str.map(s => {
                    str += "<span>" + s + "</span>";
                })

                el.innerHTML = str;
                el.spans = el.querySelectorAll('span');

                gsap.set(el.spans, {
                    autoAlpha: 0,//初期状態としてopacity: 0;とvisibility: hidden;が指定されます
                });

                gsap.to(el.spans, {
                    autoAlpha: 1,//opacity: 1;とvisibility：visible;が適用されます
                    scrollTrigger: {
                        trigger: el,//トリガーとなる要素の指定
                        // scrub: .3,//スクロール量に合わせてアニメーションが進む（数字もしくはtrue）
                        start: "center center+=200",//アニメーションの開始位置
                        end: "center center-=100",//アニメーションの終了位置
                        // markers: true,
                    },
                    stagger: {//複数の同じ要素を順番にアニメーションさせる
                        from: "random",//開始位置 start、center、edges、random、end が指定できる
                        amount: .8//ディレイの設定
                    }
                });
            })
        }

        this.scrollFade = gsap.utils.toArray(".js-scroll-fade");

        if (this.scrollFade) {
            this.scrollFade.forEach((el, i) => {

                let dataY = (el.getAttribute('data-y')) ? el.getAttribute('data-y') : 0;
                let dataX = (el.getAttribute('data-x')) ? el.getAttribute('data-x') : 0;

                gsap.set(el, {
                    autoAlpha: 0,
                    y: dataY,
                    x: dataX,
                    filter: "blur(8px)",
                });

                gsap.to(el, {
                    autoAlpha: 1,
                    duration: 1.2,
                    y: 0,
                    x: 0,
                    filter: "blur(0px)",
                    ease: "expo.inOut",
                    scrollTrigger: {
                        trigger: el,
                        start: "top bottom-=60",
                        // toggleActions: "play reverse play reverse",
                        // markers: true,
                    }
                });
            })
        }

        this.scrollBubble = gsap.utils.toArray(".js-scroll-bubble");

        if (this.scrollBubble) {
            this.scrollBubble.forEach((el, i) => {

                let dataY = (el.getAttribute('data-y')) ? el.getAttribute('data-y') : 0;
                let dataX = (el.getAttribute('data-x')) ? el.getAttribute('data-x') : 0;

                gsap.set(el, {
                    autoAlpha: 0,
                    y: dataY,
                    x: dataX,
                    scale: ".5",
                    transformOrigin: "bottom right",
                });

                gsap.to(el, {
                    autoAlpha: 1,
                    duration: 1,
                    y: 0,
                    x: 0,
                    scale: "1",
                    ease: "expo.inOut",
                    scrollTrigger: {
                        trigger: el,
                        start: "top bottom-=60",
                        // toggleActions: "play reverse play reverse",
                        // markers: true,
                    }
                });
            })
        }

        this.scrollStroke = gsap.utils.toArray(".js-scroll-stroke");

        if (this.scrollStroke) {
            this.scrollStroke.forEach((el, i) => {

                let scrollType = (el.getAttribute('data-stroke')) ? el.getAttribute('data-stroke') : "start";

                let pr_str = el.textContent.split("");
                let str = "";
                pr_str.map(s => {
                    str += "<span>" + s + "</span>";
                })

                el.innerHTML = str;
                el.spans = el.querySelectorAll('span');

                gsap.set(el.spans, {
                    autoAlpha: 0,
                });

                gsap.to(el.spans, {
                    autoAlpha: 1,
                    scrollTrigger: {
                        trigger: el,
                        start: "top bottom-=60",
                        // end: "center center-=100",
                        // markers: true,
                    },
                    stagger: {
                        from: scrollType,
                        amount: .8
                    }
                });
            })
        }

        this.scrollFixed = document.querySelectorAll(".js-scroll-fixed");
        this.scrollFixedWrap = document.querySelectorAll(".js-scroll-fixed-wrapper");

        this.questionnaireFixed = document.querySelector(".questionnaire-fixed");

        if (this.questionnaireFixed && this.scrollFixed.length > 0) {
            ScrollTrigger.matchMedia({
                "(min-width: 768px)": () => {
                    this.scrollFixed.forEach((el, i) => {
                        const wrap = this.scrollFixedWrap[i];
                        gsap.to(el, {
                            scrollTrigger: {
                                trigger: wrap,
                                start: 'top center-=' + el.clientHeight / 2,
                                end: 'bottom center+=' + el.clientHeight / 2,
                                pin: el,
                                // markers: true,
                            }
                        });
                    });
                }
            })
        }

        this.interviewFixed = document.querySelector(".interview-fixed");

        if (this.interviewFixed) {
            if (this.scrollFixed) {
                ScrollTrigger.matchMedia({
                    "(min-width: 992px)": () => {
                        this.scrollFixed.forEach((el, i) => {
                            gsap.to(el, {
                                scrollTrigger: {
                                    trigger: ".js-scroll-fixed-wrapper",
                                    start: 'top center-=' + el.clientHeight / 2,
                                    end: 'bottom center+=' + el.clientHeight / 2,
                                    pin: el,
                                    // markers: true,
                                }
                            });
                        })
                    }
                })
            }
        }

        this.scrollParallaxWrap = gsap.utils.toArray(".js-parallax-wrapper");
        this.scrollParallaxItem = gsap.utils.toArray(".js-parallax-item");

        if (this.scrollParallaxWrap) {
            if (this.scrollParallaxItem) {
                this.scrollParallaxWrap.forEach((wrap, i) => {
                    this.scrollParallaxItem.forEach((el, i) => {

                        let dataYset = (el.getAttribute('data-y-set')) ? el.getAttribute('data-y-set') : 0;
                        let dataXset = (el.getAttribute('data-x-set')) ? el.getAttribute('data-x-set') : 0;

                        let dataYto = (el.getAttribute('data-y-to')) ? el.getAttribute('data-y-to') : 0;
                        let dataXto = (el.getAttribute('data-x-to')) ? el.getAttribute('data-x-to') : 0;

                        let speed = (el.getAttribute('data-speed')) ? el.getAttribute('data-speed') : 0;

                        gsap.set(el, {
                            // autoAlpha: 0,
                            y: dataYset,
                            x: dataXset,
                        });

                        gsap.to(el, {
                            autoAlpha: 1,
                            y: dataYto,
                            x: dataXto,
                            // ease: "expo.inOut",
                            scrollTrigger: {
                                trigger: wrap,
                                start: "center bottom",
                                end: "center top",
                                scrub: .5,
                                // markers: true,
                            }
                        });
                    })
                })
            }
        }
    }
}