import barba from '@barba/core';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import LocomotiveScroll from "locomotive-scroll";

ScrollTrigger.defaults({
    scroller: document.querySelector('[data-scroll-container]'),
});

export default class{

	constructor(){

        this.isInit = false
		this.initY = 0
        this.triggerIndexFeatures = false
        this.triggerIndexProductPrimary = false
        this.triggerIndexProductSecondary = false

        window.historyScrollPosition = []
        
        barba.hooks.once((data) => {
            this.run(document.querySelector("[data-scroll-container]"),true)
            this.inTheDark(data.next.container)
            this.hideItems(data.next.container)
            this.refresh()
        })

        // barba.hooks.afterOnce((data) => {
        // })

        barba.hooks.beforeLeave((data) => {
            if(data.trigger == "back" || data.trigger == "forward"){
                if(window.historyScrollPosition.length > 0){
                    this.initY = window.historyScrollPosition.pop()
                }
            }
            window.historyScrollPosition.push(window.LocomotiveScroll.scroll.instance.scroll.y) 
        })

        barba.hooks.leave((data) => {
            window.LocomotiveScroll.destroy()
        })
        
        barba.hooks.afterEnter((data) => {
           
            if(this.isInit){
                this.run(data.next.container.querySelector("[data-scroll-container]"),false)
                ScrollTrigger.refresh();
                this.inTheDark(data.next.container)
                this.hideItems(data.next.container)

               
            }
            if(!this.isInit){
                this.isInit = true
            }
        })

        barba.hooks.after((data) => {
           
            if(data.trigger==='back' || data.trigger==='forward'){
                const sections = document.querySelectorAll('[data-scroll-trigger]')
                if( sections.length > 0 ){
                    sections.forEach( el => { 
                        el.classList.add('-visible')
                    })
                }
                setTimeout(() => {
                    ScrollTrigger.refresh();
                }, 10);
            }else{
                this.initY = 0
            }
        });
    }
    
    run(el,isOnce){
        gsap.registerPlugin(ScrollTrigger);

        let lerp =.15
        let multiplier = .4
        // Using Locomotive Scroll from Locomotive https://github.com/locomotivemtl/locomotive-scroll
        const browser = window.navigator.userAgent.toLowerCase()

                
        if(browser.indexOf('edg') != -1) {
            lerp =.2
            multiplier = .4
        }else if(browser.indexOf('chrome') != -1) {
            lerp =.2
            multiplier = .4
        }else if(browser.indexOf('safari') != -1) {
            lerp = .9
            multiplier = .8
        }else if(browser.indexOf('firefox') != -1) {
            lerp = .9
            multiplier = .8
        }
        
        window.LocomotiveScroll = new LocomotiveScroll({
            el: el,
            smooth: true,
            lerp:lerp,
            multiplier:multiplier,
            initPosition :{
                x: 0,
                y: this.initY
            },
        });
        
        // each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
        window.LocomotiveScroll.on("scroll", ScrollTrigger.update);

        // tell ScrollTrigger to use these proxy methods for the "[data-scroll-container]" element since Locomotive Scroll is hijacking things
        ScrollTrigger.scrollerProxy("[data-scroll-container]", {
            scrollTop(value) {
                return  window.LocomotiveScroll.scroll.instance.scroll.y;
            }, // we don't have to define a scrollLeft because we're only scrolling vertically.
            getBoundingClientRect() {
                return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
            },
            // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
            pinType: document.querySelector("[data-scroll-container]").style.transform ? "transform" : "fixed"
        });
    }
    refresh(){
        ScrollTrigger.addEventListener("refresh", () => {
            window.LocomotiveScroll.update()

        });        
    }
    inTheDark(container){
        const page = document.querySelector("#page")
        const els = container.querySelectorAll('[data-darkmode]')
        if(els.length > 0){
            els.forEach(el => {
                ScrollTrigger.create({
                    trigger: el,
                    start: '-95px top',
                    end: 'bottom +=95 top',
                    onToggle: (self)=> {
                        if (self.isActive) {
                            page.classList.add("-in-the-dark")
                        }else{
                            page.classList.remove("-in-the-dark")
                        }
                    },
                })
            })
        }
    }
    hideItems(container){
        const page = document.querySelector("#page")
        const els = container.querySelectorAll('[data-hidemode]')
        if(els.length > 0){
            els.forEach(el => {
                ScrollTrigger.create({
                    trigger: el,
                    start: '-95px top',
                    end: 'bottom +=95 top',
                    onToggle: (self)=> {
                        if (self.isActive) {
                            page.classList.add("-in-the-hide")
                        }else{
                            page.classList.remove("-in-the-hide")
                        }
                    },
                })
            })
        }
    }
}