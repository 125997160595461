import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class{
    /**
     * loading要素の初期設定等を行うコンストラクタ
     * DOM生成時に即時実行
     */
    constructor() {
        this.mask = document.querySelector('#js-loading__mask')
        this.top = ".js-loading__top"
        this.bottom = ".js-loading__bottom"
        this.topBack = ".js-loading__top--back"
        this.bottomBack = ".js-loading__bottom--back"
        this.copy = ".js-loading__copy"
        this.progress = ".js-loading__progress"
        this.illust = ".js-loading__illust"
        if(this.mask){
            this.mask.style.display = "block"
        }
        if(process.env.MIX_DISP_LOADING == "false" && process.env.NODE_ENV == "development"){
            if(this.mask){
                this.mask.style.display = "none" 
            }
        }
    }
    /**
     * 初期表示前に1度だけ実行
     * @param {*} data 
     */
    beforeOnce(data){
        const self = this;
        return new Promise(resolve => {
            if(data.next.namespace == "not-loading"){
                resolve()
            }
            let timeline = gsap.timeline();
            timeline
            .fromTo( this.copy,
            {
                opacity: 0
            },
            { 
                opacity: 1, 
                duration: .4,
            }).to( this.copy,
                {
                    opacity: 0,
                    delay: 1,
                    duration: .4,
                    onComplete: () => {
                        setTimeout(() => {
                            this.initialScroll();
                        }, 100);
                        resolve();
                    }
                }
            );
        })
    }
    /**
     * 初期表示時に1度だけ実行
     * @param {*} data 
     */
    once(data){
        return new Promise(resolve => {
            this.inTheDark(data.next.container)
            // this.hideItems(data.next.container)
            resolve();
        })
    }
    /**
     * 初期表示完了後に1度だけ実行
     * @param {*} data 
     */
    afterOnce(data){

        return new Promise(resolve => {
            if(data.next.namespace == "not-loading"){
                resolve();
            }
            let timeline = gsap.timeline();
            timeline
            .fromTo(this.progress,
                {
                    x: "-100px",
                    opacity: 1,
                    width: "0",
                },
                { 
                    x: "0px",
                    width: "100%",
                    duration: 1,
                    ease: "power2.inOut",
                }
            ).fromTo(this.top,
                {
                    y: "0",
                    skewY: "0",
                },
                { 
                    y: "-140%",
                    skewY: "1deg",
                    duration: 1,
                    delay: .2,
                    ease: "power2.inOut",
                }
            ).fromTo(this.topBack,
                {
                    skewY: "0",
                },
                { 
                    skewY: "8deg",
                    duration: 1,
                    ease: "power2.inOut",
                },'-=1'
            ).fromTo(this.bottom,
                {
                    y: "0",
                    skewY: "0",
                },
                { 
                    y: "140%",
                    skewY: "1deg",
                    duration: 1,
                    ease: "power2.inOut",
                },'-=1'
            ).fromTo(this.bottomBack,
                {
                    skewY: "0",
                },
                { 
                    skewY: "8deg",
                    duration: 1,
                    ease: "power2.inOut",
                },'-=1'
            ).to(this.progress,
                {
                    opacity: 0,
                    duration: .2,
                    onComplete: () => {
                        resolve();
                    }
                },'-=1'
            );
            setTimeout(() => {
                this.mask = document.querySelector('#js-loading__mask')
                if (this.mask) {
                    this.mask.style.display = "none"
                }
            }, 2000);
        });
    }
    /**
     * 次ページ読み込み前に実行
     * @param {*} data 
     */
    beforeLeave(data){
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 次ページ読み込み時に実行
     * @param {*} data 
     */
    leave(data){

        return new Promise(resolve => {
            this.mask = document.querySelector('#js-loading__mask')
            if(this.mask){
                this.mask.style.display = "block"
            }
            
            if(data.next.namespace == "not-loading"){
                resolve();
            }
            data.next.container.style.opacity = 0;

            let timeline = gsap.timeline();
            timeline
            .fromTo(this.top,
                {
                    y: "-140%",
                    skewY: "1deg",
                },
                { 
                    y: "0%",
                    skewY: "0",
                    duration: 1,
                    delay: .2,
                    ease: "power2.inOut",
                }
            ).fromTo(this.topBack,
                {
                    skewY: "8deg",
                },
                { 
                    skewY: "0",
                    duration: 1,
                    ease: "power2.inOut",
                },'-=1'
            ).fromTo(this.bottom,
                {
                    y: "140%",
                    skewY: "1deg",
                },
                { 
                    y: "0%",
                    skewY: "0",
                    duration: 1,
                    ease: "power2.inOut",
                },'-=1'
            ).fromTo(this.bottomBack,
                {
                    skewY: "8deg",
                },
                { 
                    skewY: "0",
                    duration: 1,
                    ease: "power2.inOut",
                    onComplete: () => {
                        resolve();
                    }
                },'-=1'
            );
        });
    }
    /**
     * 次ページ読み込み完了後に実行
     * @param {*} data 
     */
    afterLeave(data){
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 次ページ表示前に実行
     * @param {*} data 
     */
    beforeEnter(data){
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 次ページ表示時に実行
     * @param {*} data 
     */
    enter(data){
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 次ページ表示完了後に実行
     * @param {*} data 
     */
    afterEnter(data){
        ScrollTrigger.refresh();
        this.inTheDark(data.next.container)
        // this.hideItems(data.next.container)
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * すべて完了後に実行
     * @param {*} data 
     */
    after(data){

        return new Promise(resolve => {


            if(data.next.namespace == "not-loading"){
                this.initialScroll ()
                data.next.container.style.opacity = 1;
                resolve();
            }

            let timeline = gsap.timeline();
            timeline
            .fromTo(this.top,
                {
                    y: "0%",
                    skewY: "0",
                },
                { 
                    y: "-140%",
                    skewY: "1deg",
                    duration: 1,
                    delay: .2,
                    ease: "power2.inOut",
                }
            ).fromTo(this.topBack,
                {
                    skewY: "0",
                },
                { 
                    skewY: "8deg",
                    duration: 1,
                    ease: "power2.inOut",
                },'-=1'
            ).fromTo(this.bottom,
                {
                    y: "0",
                    skewY: "0",
                },
                { 
                    y: "140%",
                    skewY: "1deg",
                    duration: 1,
                    ease: "power2.inOut",
                },'-=1'
            ).fromTo(this.bottomBack,
                {
                    skewY: "0",
                },
                { 
                    skewY: "8deg",
                    duration: 1,
                    ease: "power2.inOut",
                    onComplete: () => {
                        resolve();
                    }
                },'-=1'
            );
            page.classList.remove("-in-the-dark")
            page.classList.remove("-in-the-hide")
            this.initialScroll ()
            data.next.container.style.opacity = 1;
            setTimeout(() => {
                this.mask = document.querySelector('#js-loading__mask')
                if (this.mask) {
                    this.mask.style.display = "none"
                }
            }, 2000);            
        });
    }
    initialScroll (){
        if( location.hash == "" ){
            window.scrollTo(0,0);
        }else{
            let digit = location.hash.replace(/^#/,"");
            let anchor = document.getElementById( digit );
            if ( !anchor ) return;
            
            if(anchor){
                const rect = anchor.getBoundingClientRect();
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                let top = rect.top + scrollTop;
                const header = document.querySelector('[data-header]');
                if(header){
                    if(header){
                        top = top - header.clientHeight;
                    }
                }
                window.LocomotiveScroll.scrollTo(anchor,{offset:0,duration: 500})
            }
        }
    }
    inTheDark(container){
        const page = document.querySelector("#page")
        const els = container.querySelectorAll('[data-darkmode]')
        if(els.length > 0){
            els.forEach(el => {
                ScrollTrigger.create({
                    trigger: el,
                    start: '-95px top',
                    end: 'bottom +=95 top',
                    // markers:true,
                    onToggle: (self)=> {
                        if (self.isActive) {
                            page.classList.add("-in-the-dark")
                        }else{
                            page.classList.remove("-in-the-dark")
                        }
                    },
                })
            })
        }
    }
    hideItems(container){
        const page = document.querySelector("#page")
        const els = container.querySelectorAll('[data-hidemode]')
        if(els.length > 0){
            els.forEach(el => {
                ScrollTrigger.create({
                    trigger: el,
                    start: '-95px top',
                    end: 'bottom +=95 top',
                    // markers:true,
                    onToggle: (self)=> {
                        if (self.isActive) {
                            page.classList.add("-in-the-hide")
                        }else{
                            page.classList.remove("-in-the-hide")
                        }
                    },
                })
            })
        }
    }
}