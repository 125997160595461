
import barba from '@barba/core';
import Splide from '@splidejs/splide';
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

export default class {

    constructor() {

        barba.hooks.beforeOnce((data) => {
            this.blogSlider(data.next.container)
            this.blogSingleSlider(data.next.container)
            this.mouseStalkerWorks(data.next.container)

        })
        barba.hooks.after((data) => {
            this.blogSlider(data.next.container)
            this.blogSingleSlider(data.next.container)
            this.mouseStalkerWorks(data.next.container)
        });

    }

    blogSlider(container) {
        const blogSlider = container.querySelector('.home-blog__slider');
        if (blogSlider) {
            const slide = new Splide('.home-blog__slider', {
                type: 'loop',
                rewind: false,
                pagination: false,
                // focus: "left",
                perPage: 3,
                perMove: 1,
                gap: 20,
                speed: 1000,
                autoplay: true,
                interval: 6000,
                arrows: true,
                arrowPath: "M13.0991 20.2551L16.5082 23.8735C16.6306 24.0253 16.8458 24.043 16.9888 23.913C17.1318 23.783 17.1485 23.5546 17.026 23.4028C17.0146 23.3886 17.0021 23.3754 16.9888 23.3633L14.1627 20.36H27.6591C27.8474 20.36 28 20.198 28 19.9982C28 19.7983 27.8474 19.6363 27.6591 19.6363H14.1627L16.9888 16.6367C17.1318 16.5067 17.1485 16.2783 17.026 16.1265C16.9035 15.9747 16.6883 15.957 16.5453 16.087C16.532 16.0992 16.5196 16.1123 16.5082 16.1265L13.0991 19.7449C12.967 19.886 12.967 20.1139 13.0991 20.2551Z",
                classes: {
                    arrows: 'home-blog__slider-nav',
                    prev: 'home-blog__slider-prev',
                    next: 'home-blog__slider-next'
                },
                breakpoints: {
                    575: {
                        perPage: 2,
                        gap: 10,
                    },
                    767: {
                        perPage: 2,
                        gap: 15,
                    }
                }
            });
            slide.mount();
        }
    }
    blogSingleSlider(container) {
        const el = container.querySelector('.js-blog__slider');
        const thumbSlider = container.querySelector('.js-blog__thumb-slider');

        if (el && thumbSlider) {
            const blogSingleSlide = new Splide('.js-blog__slider', {
                rewind: true,
                pagination: false,
                arrows: false,
            }).mount();

            const thumbSlide = new Splide('.js-blog__thumb-slider', {
                fixedWidth: 100,
                fixedHeight: 60,
                gap: 10,
                rewind: true,
                pagination: false,
                arrows: false,
                cover: true,
                isNavigation: true,
                focus: "left",
                breakpoints: {
                    991: {
                        fixedWidth: 70,
                        fixedHeight: 40,
                        gap: 4,
                    }
                }
            });

            thumbSlide.sync(blogSingleSlide).mount();
        }
    }
    mouseStalkerWorks(container) {
        const elms = container.querySelectorAll('.mouse-stalker__slider--works');

        for ( var i = 0; i < elms.length; i++ ) {
            new Splide( elms[ i ] ,{
                type: 'loop',
                rewind: true,
                pagination: false,
                arrows: false,
                pauseOnHover: false,
                autoScroll: {
                    speed: 1,   
                },
            }).mount({ AutoScroll });
        }
    }
}