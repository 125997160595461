
import barba from '@barba/core';
import anime from 'animejs';
import gsap from 'gsap';

export default class {

    constructor() {
        this.drawerLinks = []
        this.opened = false

        this.hamburger_id = "#js-hamburger"
        this.drawer_id = "#js-drawer"
        this.drawer_link_class = ".drawer__nav--link"

        this.drawer_str_class = ".drawer__nav--str"

        barba.hooks.once((data) => {
            this.init()
        })
        barba.hooks.after((data) => {
            this.init()
        });
    }

    init() {
        this.body = document.body;
        this.hamburger = document.querySelector(this.hamburger_id);
        this.drawer = document.querySelector(this.drawer_id);
        this.drawerBack = document.querySelector(".drawer__back");
        if (this.drawer) {
            this.drawerLinks = this.drawer.querySelectorAll(this.drawer_link_class);
            this.drawerStr = this.drawer.querySelectorAll(this.drawer_str_class);
            if (this.hamburger) {
                this.hamburger.addEventListener('click', (e) => {
                    this.drawerClick(e);
                })
            }
            if (this.drawerLinks) {
                if (this.drawerLinks.length > 0) {
                    this.drawerLinks.forEach((drawerLink) => {
                        this.drawerRemoveClass(drawerLink)
                    });
                }
            }
            if (this.drawerBack) {
                this.drawerBack.addEventListener('click', () => {
                    this.close();
                })
            }
        }
    }
    drawerClick(e) {
        this.drawerToggleClass();
    }
    drawerToggleClass() {
        if (!this.opened) {
            this.open();
        } else {
            this.close();
        }
    }
    drawerRemoveClass(drawerLink) {
        drawerLink.addEventListener('click', () => {
            setTimeout(() => {
                this.close();
            }, 300)
        })
    }
    open() {
        this.body.classList.add('active__drawer');
        this.hamburger.setAttribute('aria-expanded', true)
        gsap.set(".drawer__nav--item", {
            autoAlpha: 0,
            x:"50%"
        });
        gsap.to(".drawer__nav--item", {
            autoAlpha: 1,
            x:0,
            delay:.4,
            stagger: {
                from: "start",
                amount: .4
            }
        });
        this.opened = true;
    }
    close() {
        this.body.classList.remove('active__drawer');
        this.hamburger.setAttribute('aria-expanded', false)
        gsap.to(".drawer__nav--item", {
            autoAlpha: 0,
            x:"50%",
            stagger: {
                from: "end",
                amount: .4
            }
        });
        this.opened = false;
        setTimeout(() => {
            this.drawer.scrollTop = 0;
        }, 300)
    }
}