import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class {

    constructor() {
        barba.hooks.afterOnce((data) => {
            this.thoughts(data.next.container);
            this.construction(data.next.container)
            this.blogSlider(data.next.container)
        });
        barba.hooks.after((data) => {
            this.thoughts(data.next.container);
            this.construction(data.next.container)
            this.blogSlider(data.next.container)
        });
    }

    thoughts(container) {

        // this.thoughtsBG = document.querySelector(".js-thoughts__image");
        // this.thoughtsContent = document.querySelector(".js-thoughts__content");

        // if (this.thoughtsBG) {
        //     ScrollTrigger.matchMedia({
        //         "(min-width: 992px)": () => {
        //             gsap.to(this.thoughtsBG, {
        //                 scrollTrigger: {
        //                     trigger: this.thoughtsBG,
        //                     endTrigger:this.thoughtsContent,
        //                     start: 'top top',
        //                     end: 'bottom bottom',
        //                     pin: this.thoughtsBG,
        //                     // markers: true,
        //                 }
        //             });
        //         }
        //     })
        // }

        const thoughtsSlider = document.querySelectorAll(".js-thoughts__image");

        if (thoughtsSlider) {
            thoughtsSlider.forEach((slide) => {
                gsap.to(slide, {
                    x:"-33.333%",
                    ease: "power3.inOut",
                    scrollTrigger: {
                        trigger: ".thoughts__image--wrapper",
                        start: 'top top+=100',
                        end: 'bottom bottom-=100',
                        scrub: 5,
                        // markers: true,
                    }
                });
                ScrollTrigger.matchMedia({
                    "(min-width: 992px)": () => {
                        gsap.to(slide, {
                            x:"-33.333%",
                            ease: "power3.inOut",
                            scrollTrigger: {
                                trigger: "#thoughts",
                                start: 'top top',
                                end: 'bottom bottom',
                                scrub: 2,
                                // markers: true,
                            }
                        });
                    }
                })
            })
        }
    }

    construction(container) {
        const innerContainers = container.querySelectorAll('.construction__inner');
    
        if (innerContainers) {
            innerContainers.forEach((innerContainer) => {
                const listWrapperEl = innerContainer.querySelector('.construction__list');
                const listEl = innerContainer.querySelector('.construction__list--inner');
    
                const windowHeight = window.innerHeight;

                if (windowHeight > 680) {
                    ScrollTrigger.matchMedia({
                        "(min-width: 1200px)": () => {
                            gsap.to(listEl, {
                                x: () => -(listEl.clientWidth - listWrapperEl.clientWidth),
                                ease: 'none',
                                scrollTrigger: {
                                    trigger: innerContainer,
                                    start: 'top top+=200',
                                    end: () => `+=${listEl.clientWidth - listWrapperEl.clientWidth}`,
                                    scrub: true,
                                    pin: true,
                                    anticipatePin: 1,
                                    invalidateOnRefresh: true,
                                    // markers: true,
                                },
                            });
                        },
                    });
                }
            });
        }
    } 

    blogSlider(container) {

        this.scrollFixed = document.querySelectorAll(".js-scroll-fixed");
        this.scrollFixedWrap = document.querySelectorAll(".js-scroll-fixed-wrapper");

        if (this.scrollFixed.length > 0) {
            ScrollTrigger.matchMedia({
                "(min-width: 768px)": () => {
                    this.scrollFixed.forEach((el, i) => {
                        const wrap = this.scrollFixedWrap[i];
                        gsap.to(el, {
                            scrollTrigger: {
                                trigger: wrap,
                                start: 'top center-=' + el.clientHeight / 2,
                                end: 'bottom center+=' + el.clientHeight / 2,
                                pin: el,
                                // markers: true,
                            }
                        });
                    });
                }
            })
        }
    }      
}
